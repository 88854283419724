import { render, staticRenderFns } from "./nullPage.vue?vue&type=template&id=563073c1&scoped=true&"
import script from "./nullPage.vue?vue&type=script&lang=js&"
export * from "./nullPage.vue?vue&type=script&lang=js&"
import style0 from "./nullPage.vue?vue&type=style&index=0&id=563073c1&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "563073c1",
  null
  
)

export default component.exports